@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


/*
pixeles > px
viewportWidth > vw
viewportHeight > vh

*/


