::view-transition-old(root) {
    animation: scaleOut 0.3s ease-in-out;
}

::view-transition-new(root) {
    animation: scaleIn 0.3s ease-in-out;
}


@keyframes scaleIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}


@keyframes scaleOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
